import React, { useEffect, useState } from 'react';
import axios from 'axios'; 
import styles from './ServerLog.module.css'; // Correct path to the module

const ServerLog = () => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchLogs = async () => {
        try {
            const response = await axios.get('https://server.earf.tv/server-log', {
                headers: {
                    'x-api-key': process.env.REACT_APP_EARF_API_KEY,
                },
            });

            setLogs(response.data);
            setLoading(false);
        } catch (err) {
            setError('Error fetching logs');
            setLoading(false);
        }
    };

    useEffect(() => {
        // Fetch logs when component mounts
        fetchLogs();

        // Set up an interval to re-fetch logs every 60 seconds
        const interval = setInterval(() => {
            fetchLogs(); // Re-fetch logs every minute
        }, 60000); // 60 seconds

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, []);

    // Function to convert timestamp to "how long ago"
    const timeAgo = (timestamp) => {
        const now = new Date();
        const timeDifference = now - new Date(timestamp); // Difference in milliseconds
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        }
    };

    // Function to convert UTC timestamp to AEST
    const formatTimestampToAEST = (timestamp) => {
        const date = new Date(timestamp);
        const options = {
            timeZone: 'Australia/Brisbane',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };

        return date.toLocaleString('en-AU', options);
    };

    if (loading) return (
        <div className={styles.loading}>
            <div className="spinner-wrapper">
                <div className="spinner"></div> {/* Spinner Element */}
            </div>
        </div>
    );
    
    if (error) return <div className={styles.error}>{error}</div>;

    return (
        <div className={styles.serverLogContainer}>
            <div className={styles.serverLogHeader}>
                <h2 className={styles.title}>Server Log</h2>
                <p className={styles.serverUrl}>server.earf.tv</p>
            </div>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>IP Address</th>
                        <th>Timestamp</th>
                        <th>Success</th>
                    </tr>
                </thead>
                <tbody>
                    {logs
                        .slice() // Create a shallow copy of the array
                        .reverse() // Reverse the array so that the latest logs are first
                        .map((log, index) => (
                            <tr key={index} className={styles.row}>
                                <td>{log.username}</td>
                                <td>{log.ip}</td>
                                <td>
                                    <span title={formatTimestampToAEST(log.timestamp)}>
                                        {timeAgo(log.timestamp)}
                                    </span>
                                </td>
                                <td className={log.success ? styles.successYes : styles.successNo}>
                                    {log.success ? 'Yes' : 'No'}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
};

export default ServerLog;
