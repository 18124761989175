import React, { useState, useEffect, useRef } from 'react';
import './GunViolence.css'; // Custom styling for gun violence alerts

const GunViolence = () => {
  const [gunViolenceList, setGunViolenceList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // Start from page 0
  const [loading, setLoading] = useState(true); // Start with loading
  const [hasMore, setHasMore] = useState(true); // Track if there are more items to load

  const listRef = useRef(null); // Create a ref for the gun-violence-list

  // Get the API key from environment variables
  const apiKey = process.env.REACT_APP_EARF_API_KEY;

  // Fetch Gun Violence data
  const fetchGunViolenceData = async (pageNumber) => {
    setLoading(true); // Start loading state
    try {
      const response = await fetch(`https://server.earf.tv/gun-violence?page=${pageNumber}`, {
        headers: {
          'x-api-key': apiKey, // Add the API key to the request headers
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      // If no data is returned, indicate no more items
      if (data.length === 0) {
        setHasMore(false); // No more data to load
        return; // Exit if no data is returned
      }

      // Set new incidents, replacing the old list
      setGunViolenceList(data); // Set the state with fetched data
    } catch (error) {
      console.error('Error fetching gun violence data:', error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  useEffect(() => {
    fetchGunViolenceData(currentPage); // Fetch data whenever the page changes
  }, [currentPage]);

  // Scroll to the top of the gun-violence-list after navigating
  const scrollToTop = () => {
    if (listRef.current) {
      listRef.current.scrollTo({
        top: 0,
        behavior: 'smooth', // This provides a smooth scroll effect
      });
    }
  };

  // Handle Next Page
  const handleNextPage = () => {
    if (currentPage < 2) { // Only increment if currentPage is less than 2
      setCurrentPage((prevPage) => prevPage + 1); // Increment page number
      scrollToTop(); // Scroll to the top of the gun-violence-list after navigating
    }
  };

  // Handle Previous Page
  const handlePreviousPage = () => {
    if (currentPage > 0) { // Ensure we don't go below page 0
      setCurrentPage((prevPage) => prevPage - 1); // Decrement page number
      scrollToTop(); // Scroll to the top of the gun-violence-list after navigating
    }
  };

  // Render the loader
  const renderLoader = () => (
    <div className="full-container">
    <div className="spinner-wrapper">
      <div className="spinner"></div> {/* Spinner Element */}
    </div>
  </div>
  );

  // Render the list of gun violence incidents
  const renderGunViolenceList = () => (
    <div className="gun-violence-list" ref={listRef}>
      {gunViolenceList.map((incident, index) => (
        <div key={index} className="gun-violence-card">
          <div className="gun-violence-card-content">
            <div className="gun-violence-info">
              <h3 className="gun-violence-title">
                {incident.address}, <span style={{ color: 'var(--earf-blue)' }}>{incident.city}, {incident.state}</span> {/* Display Address, City, State */}
              </h3>
              <p className="gun-violence-details">
                <div style={{ textAlign: 'center' }}> {/* Center the content */}
                  <img src="/gravestone.png" alt="Gravestone Icon" />
                  <span className="killed">{incident.killed}</span>
                  <span className="icon-label">Killed</span> {/* Label for Killed */}
                </div>
                <div style={{ textAlign: 'center' }}> {/* Center the content */}
                  <img src="/hospital.png" alt="Hospital Icon" />
                  <span className="injured">{incident.injured}</span>
                  <span className="icon-label">Injured</span> {/* Label for Injured */}
                </div>
              </p>
              <p className="gun-violence-date">
                {incident.date} {/* Display Date */}
              </p>
            </div>
            <a
              href={incident.operations.viewSource || incident.operations.viewIncident} // Link to source or incident
              target="_blank"
              rel="noopener noreferrer"
              className="gun-violence-more-info-button"
            >
              Read More
            </a>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <div className="header-container">
        <h2>USA Gun Violence</h2>
        <div className="pagination-buttons">
          {currentPage > 0 && ( // Show Previous button only if not on page 0
            <button 
              className="prev-page-button" 
              onClick={handlePreviousPage}
            >
              &larr; Previous Page
            </button>
          )}
          {hasMore && currentPage < 2 && ( // Show Next button only if more pages exist
            <button 
              className="next-page-button" 
              onClick={handleNextPage}
            >
              Next Page &rarr;
            </button>
          )}
        </div>
      </div>
      {loading ? renderLoader() : renderGunViolenceList()} {/* Show loader or incidents based on loading state */}
    </div>
  );
};

export default GunViolence;
