import React, { useState, useEffect } from 'react';
import Loader from '../Loader/Loader';
import FeatureTags from './FeatureTags/FeatureTags';
import KnownAsTags from './KnownAsTags/KnownAsTags';
import styles from './StreamDescription.module.css';

const StreamDescription = ({ qid, countryCode }) => {
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchWikidataDetails = async () => {
      setIsLoading(true);

      try {
        const response = await fetch(
          `https://www.wikidata.org/w/api.php?action=wbgetentities&ids=${qid}&props=labels|descriptions|sitelinks&format=json&origin=*`
        );
        const data = await response.json();

        if (data.entities && data.entities[qid]) {
          const entity = data.entities[qid];
          const tempName = entity.labels?.en?.value || 'Unknown Name';
          setName(tempName);

          const wikipediaTitle = entity.sitelinks?.enwiki?.title;
          if (wikipediaTitle) {
            const wikipediaDescription = await fetchWikipediaDescription(wikipediaTitle);
            setDescription(wikipediaDescription || entity.descriptions?.en?.value || '');
          } else {
            setDescription(entity.descriptions?.en?.value || 'No description available.');
          }
        } else {
          setName('Unknown Name');
          setDescription('No description available.');
        }
      } catch (error) {
        console.error('Error fetching data from Wikidata:', error);
        setName('Error');
        setDescription('');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchWikipediaDescription = async (title) => {
      try {
        const response = await fetch(
          `https://en.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(title)}`
        );
        const data = await response.json();
        return data.extract || '';
      } catch (error) {
        console.error('Error fetching Wikipedia description:', error);
        return '';
      }
    };

    if (qid) {
      fetchWikidataDetails();
    }
  }, [qid]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        description && (
          <div className={styles.description}>
            <div className={styles['content-container']}>
              <div>
                <div className={styles['about-container']}>
                  <h2 className={styles['about-header']}>
                    About <span className={styles['highlight-blue']}>{name}            {countryCode && (
                      <img
                        src={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`}
                        alt={`${countryCode} flag`}
                        className={styles.flag}
                      />
                    )}</span>

                  </h2>
                  <KnownAsTags qid={qid} className={styles['known-as-tags']} />
                </div>

                <p className={`${styles.aboutdescription} ${styles['margin-top-10']}`}>{description}</p>
                {qid && <FeatureTags qid={qid} className="feature-tags-mobile" />}

                <div className={styles.descriptionnotice}>
                  Information may not always be accurate.
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default StreamDescription;
