import React, { createContext, useState, useEffect } from 'react';

// Create a context
export const AdminContext = createContext();

// Provide context to all components
export const AdminProvider = ({ children }) => {
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false); // Start with false
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    // Retrieve from localStorage and parse as boolean
    const storedAuthState = JSON.parse(localStorage.getItem('isAdminAuthenticated')) === true;
    setIsAdminAuthenticated(storedAuthState);
    setIsLoading(false); // Authentication status has been checked
  }, []);

  const loginAdmin = () => {
    setIsAdminAuthenticated(true);
    localStorage.setItem('isAdminAuthenticated', true);
  };

  const logoutAdmin = () => {
    setIsAdminAuthenticated(false);
    localStorage.removeItem('isAdminAuthenticated');
  };

  if (isLoading) {
    return <div>Loading...</div>; // Or return a loading spinner, if desired
  }

  return (
    <AdminContext.Provider value={{ isAdminAuthenticated, loginAdmin, logoutAdmin }}>
      {children}
    </AdminContext.Provider>
  );
};
