import React, { useState, useEffect } from 'react';
import styles from './LocationInfo.module.css';
import { useLocation } from 'react-router-dom';

// Helper function to fetch the initial time based on coordinates
const fetchTime = async (latitude, longitude) => {
  try {
    const response = await fetch(`https://api.timezonedb.com/v2.1/get-time-zone?key=${process.env.REACT_APP_TIMEZONEDB_API_KEY}&format=json&by=position&lat=${latitude}&lng=${longitude}`);
    const data = await response.json();
    return data.formatted;  // Returns the formatted time
  } catch (error) {
    console.error('Error fetching time:', error);
    return null;
  }
};

// Helper function to fetch weather based on coordinates
const fetchWeather = async (latitude, longitude) => {
  const openWeatherApiKey = process.env.REACT_APP_OPENWEATHER_API_KEY;
  const weatherApiUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&units=metric&appid=${openWeatherApiKey}`;

  try {
    const response = await fetch(weatherApiUrl);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching weather:', error);
    return null;
  }
};

// Helper function to capitalize the first letter of each word in the description
const capitalizeDescription = (description) => {
  return description.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const LocationInfo = ({ coordinates }) => {
  const [localTime, setLocalTime] = useState(null);
  const [weather, setWeather] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Ensure that we have valid coordinates
    if (!coordinates || coordinates.length !== 2) return;
  
    const getLocationData = async () => {
      let [longitude, latitude] = coordinates;
  
      // Fetch initial time and weather
      const time = await fetchTime(latitude, longitude);
      if (time) {
        setLocalTime(new Date(time));
      }
  
      const weatherData = await fetchWeather(latitude, longitude);
      setWeather(weatherData);
    };
  
    getLocationData();
  }, [coordinates, location.state]);

  // This useEffect runs every second to update the local time like a clock
  useEffect(() => {
    if (localTime) {
      const interval = setInterval(() => {
        setLocalTime((prevTime) => new Date(prevTime.getTime() + 1000)); // Add 1 second
      }, 1000);

      return () => clearInterval(interval); // Clear the interval on component unmount
    }
  }, [localTime]);

  // Handle loading state
  if (!localTime || !weather) {
    return <div></div>;
  }

  // Convert Celsius to Fahrenheit
  const tempCelsius = Math.round(weather.main.temp);
  const tempFahrenheit = Math.round((tempCelsius * 9/5) + 32);

  return (
    <div className={styles['location-info-container']}>
      <div className={styles['location-info']}>
        <div className={styles['location-time']}>
          <p>{localTime.toLocaleTimeString()}</p>
        </div>
        {weather && weather.weather && weather.weather.length > 0 ? (
          <div className={styles['location-weather']}>
            <div className={styles['weather-info']}>
              <img
                src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`}
                alt={capitalizeDescription(weather.weather[0].description)}
              />
              <div className={styles['weather-details']}>
                <p>
                  {tempCelsius}°C / {tempFahrenheit}°F - {capitalizeDescription(weather.weather[0].description)}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles['location-weather']}>
            <p>Weather data not available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationInfo;
