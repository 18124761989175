import React, { useEffect, useState } from 'react';
import styles from './PlaceInfoBar.module.css';

const PlaceInfoBar = ({ wikidata }) => {
  const [weather, setWeather] = useState(null);
  const [airQuality, setAirQuality] = useState(null);
  const [clockTime, setClockTime] = useState(null);
  const [initialTime, setInitialTime] = useState(null);

  const OPENWEATHER_API_KEY = process.env.REACT_APP_OPENWEATHER_API_KEY;
  const TIMEZONEDB_API_KEY = process.env.REACT_APP_TIMEZONEDB_API_KEY;

  useEffect(() => {
    if (wikidata) {
      fetchLocationData(wikidata);
    }
  }, [wikidata]);

  useEffect(() => {
    if (initialTime) {
      const intervalId = setInterval(() => {
        const updatedTime = new Date(initialTime.getTime() + 1000);
        setInitialTime(updatedTime);
        updateClockDisplay(updatedTime);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [initialTime]);

  const updateClockDisplay = (currentTime) => {
    const time = currentTime.toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });
    setClockTime(time);
  };

  const fetchLocationData = async (placeName) => {
    try {
      const { lat, lon } = await fetchCoordinatesFromPlaceName(placeName);

      const weatherResponse = await fetch(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${OPENWEATHER_API_KEY}&units=metric`
      );
      const weatherData = await weatherResponse.json();
      setWeather({
        temp: weatherData.main.temp,
        status: capitalizeFirstLetter(weatherData.weather[0].description),
        icon: `https://openweathermap.org/img/wn/${weatherData.weather[0].icon}.png`,
      });

      const timeResponse = await fetch(
        `https://api.timezonedb.com/v2.1/get-time-zone?key=${TIMEZONEDB_API_KEY}&format=json&by=position&lat=${lat}&lng=${lon}`
      );
      const timeData = await timeResponse.json();
      const fetchedTime = new Date(timeData.formatted);
      setInitialTime(fetchedTime);

      const airQualityResponse = await fetch(
        `https://api.openweathermap.org/data/2.5/air_pollution?lat=${lat}&lon=${lon}&appid=${OPENWEATHER_API_KEY}`
      );
      const airQualityData = await airQualityResponse.json();
      const aqi = airQualityData.list[0].main.aqi;
      setAirQuality(mapAQIToLabel(aqi));
    } catch (error) {
      console.error('Error fetching location data:', error);
    }
  };

  const mapAQIToLabel = (aqi) => {
    switch (aqi) {
      case 1:
        return 'Good';
      case 2:
        return 'Fair';
      case 3:
        return 'Moderate';
      case 4:
        return 'Poor';
      case 5:
        return 'Very Poor';
      default:
        return 'Unknown';
    }
  };

  const capitalizeFirstLetter = (text) => {
    return text.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const fetchCoordinatesFromPlaceName = async (placeName) => {
    const geocodeResponse = await fetch(
      `https://api.openweathermap.org/geo/1.0/direct?q=${encodeURIComponent(placeName)}&limit=1&appid=${OPENWEATHER_API_KEY}`
    );
    const geocodeData = await geocodeResponse.json();
    if (geocodeData.length > 0) {
      const { lat, lon } = geocodeData[0];
      return { lat, lon };
    } else {
      throw new Error('Unable to fetch coordinates for place name');
    }
  };

  return (
    <div className={styles['place-info-bar']}>
      {clockTime && (
        <span className={styles['place-info-item']}>
          🕒 {clockTime}
        </span>
      )}

      {weather && (
        <span className={styles['place-info-item']}>
          <img src={weather.icon} alt={weather.status} className={styles['weather-icon']} />
          {weather.temp}°C - {weather.status}
        </span>
      )}

      {airQuality && <span className={styles['place-info-item']}>🌫️ Air Quality: {airQuality}</span>}
    </div>
  );
};

export default PlaceInfoBar;
