import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './VideoSection.module.css';
import PlaceInfoBar from './PlaceInfoBar';

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const VideoSection = ({ videoData }) => {
  const navigate = useNavigate();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [channelData, setChannelData] = useState({
    channelName: '',
    channelThumbnail: '',
    channelUrl: '',
    liveViewers: 0,
  });

  const [userInteracted, setUserInteracted] = useState(false);
  const sliderRef = useRef(null);
  const [loadedImages, setLoadedImages] = useState({});
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    focusOnSelect: true,
    arrows: true,
    afterChange: (index) => {
      const newSelectedVideo = videoData[index];
      setSelectedVideo(newSelectedVideo);
    },
  };

  const fetchVideoDetails = async (videoId) => {
    try {
      const videoResponse = await fetch(
        `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet,liveStreamingDetails&key=${API_KEY}`
      );
      const videoData = await videoResponse.json();

      if (videoData.items.length > 0) {
        const videoInfo = videoData.items[0];
        const channelId = videoInfo.snippet.channelId;

        const liveViewers = videoInfo.liveStreamingDetails?.concurrentViewers || 0;

        const channelResponse = await fetch(
          `https://www.googleapis.com/youtube/v3/channels?id=${channelId}&part=snippet&key=${API_KEY}`
        );
        const channelData = await channelResponse.json();
        const channelInfo = channelData.items[0];

        setChannelData({
          channelName: channelInfo.snippet.title,
          channelThumbnail: channelInfo.snippet.thumbnails.default.url,
          channelUrl: `https://www.youtube.com/channel/${channelId}`,
          liveViewers,
        });
      }
    } catch (error) {
      console.error('Error fetching video details:', error);
    }
  };

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * videoData.length);
    const initialVideo = videoData[randomIndex];
    setSelectedVideo(initialVideo);
    fetchVideoDetails(initialVideo.youtubeId);

    if (sliderRef.current) {
      sliderRef.current.slickGoTo(randomIndex);
    }
  }, [videoData]);

  useEffect(() => {
    if (selectedVideo) {
      fetchVideoDetails(selectedVideo.youtubeId);
    }
  }, [selectedVideo]);

  // Auto-change video every 15 seconds if no user interaction
  useEffect(() => {
    if (!userInteracted) {
      const interval = setInterval(() => {
        const currentIndex = videoData.findIndex((video) => video.id === selectedVideo?.id);
        const nextIndex = (currentIndex + 1) % videoData.length;
        const nextVideo = videoData[nextIndex];
        setSelectedVideo(nextVideo);

        if (sliderRef.current) {
          sliderRef.current.slickGoTo(nextIndex);
        }
      }, 15000);

      return () => clearInterval(interval);
    }
  }, [selectedVideo, userInteracted, videoData]);

  const handleUserInteraction = () => {
    setUserInteracted(true);
  };

  const handleTagClick = (tag) => {
    navigate(`/browse/${tag}`);
  };

  // Handle image load to remove blur
  const handleImageLoad = (index) => {
    setLoadedImages((prevLoadedImages) => ({
      ...prevLoadedImages,
      [index]: true,
    }));
  };

  return (
    <div className={styles['video-section-container']}>
      <div className={styles['video-section-left']}>
        <h2 className={styles['video-section-title']}>{selectedVideo?.title}</h2>

        <div className={styles['video-section-channel-info']}>
          <a href={channelData.channelUrl} target="_blank" rel="noopener noreferrer">
            <img
              src={channelData.channelThumbnail}
              alt={channelData.channelName}
              className={styles['video-section-channel-thumbnail']}
            />
          </a>
          <div className={styles['video-section-channel-details']}>
            <a
              href={channelData.channelUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={styles['video-section-channel-name']}
            >
              {channelData.channelName}
            </a>
            <p className={styles['video-section-wikidata']}>{selectedVideo?.wikidata}</p>
            <p className={styles['video-section-view-count']}>
              {channelData.liveViewers > 0
                ? `${channelData.liveViewers} watching live`
                : `Not live`}
            </p>
          </div>
        </div>

        <div className={styles['video-section-tags']}>
          {selectedVideo?.tags.map((tag, index) => (
            <span
              key={index}
              className={styles['video-section-tag']}
              onClick={() => handleTagClick(tag)}
            >
              {tag}
            </span>
          ))}
        </div>

        {selectedVideo?.wikidata && <PlaceInfoBar wikidata={selectedVideo.wikidata} />}

        <div className={styles['video-section-description']}>
  <p dangerouslySetInnerHTML={{ __html: selectedVideo?.description }}></p>
</div>


        <Slider
          {...sliderSettings}
          className={styles['video-section-carousel']}
          ref={sliderRef}
          onClick={handleUserInteraction}
          onSwipe={handleUserInteraction}
        >
          {videoData.map((video, index) => (
            <div
              key={video.id}
              className={`${styles['video-section-thumbnail-item']} ${selectedVideo?.id === video.id ? styles['active'] : ''}`}
              onClick={() => {
                setSelectedVideo(video);
                handleUserInteraction();
              }}
            >
              <img
                src={video.thumbnail}
                alt={video.title}
                className={`${styles['video-section-thumbnail-image']} ${loadedImages[index] ? styles['loaded'] : styles['loading']}`}
                loading="lazy"
                onLoad={() => handleImageLoad(index)}
              />
              {!loadedImages[index] && <div className={styles['image-placeholder']}></div>}
              <p className={styles['video-section-thumbnail-title']}>{video.title}</p>
              <p className={styles['video-section-thumbnail-wikidata']}>{video.wikidata}</p>
            </div>
          ))}
        </Slider>
      </div>

      <div className={styles['video-section-right']}>
        <div className={styles['video-section-video-wrapper']}>
          {!iframeLoaded && <div className={styles['iframe-placeholder']}></div>} {/* Placeholder before iframe loads */}
          <iframe
            src={`https://www.youtube.com/embed/${selectedVideo?.youtubeId}?autoplay=1&mute=1`}
            title={selectedVideo?.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className={`${iframeLoaded ? styles['loaded-iframe'] : styles['loading-iframe']}`}
            onLoad={() => setIframeLoaded(true)}  // Mark iframe as loaded
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
