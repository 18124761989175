import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import Link
import styles from './NotFound.module.css'; // Correct import

const NotFound = () => {
    const [countdown, setCountdown] = useState(10); // Countdown set back to 10 seconds
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);

        // Redirect to home page after countdown reaches 0
        if (countdown === 0) {
            navigate('/');
        }

        // Clean up the timer
        return () => clearInterval(timer);
    }, [countdown, navigate]);

    // Determine countdown color based on time left
    const getCountdownColor = () => {
        if (countdown > 7) {
            return styles.green; // Green (10-8 seconds)
        } else if (countdown > 4) {
            return styles.yellow; // Yellow (7-5 seconds)
        } else if (countdown > 2) {
            return styles.orange; // Orange (4-3 seconds)
        } else {
            return styles.red; // Red (2-0 seconds)
        }
    };

    return (
        <div className={styles['not-found-container']}>
            <img src={window.logoUrl} alt="Earf.tv Logo" className={styles.logo} />
            <h1><span className={styles['earf-blue']}>EARF</span>.TV</h1>
            <h3 className={styles.sorryText}>Sorry, this page cannot be found.</h3>
            <p className={styles.redirectText}>
                Taking you Home in <span className={getCountdownColor()}>{countdown}</span> seconds, or <Link to="/" className={styles.link}>click here</Link> to go now.
            </p>
        </div>
    );
};

export default NotFound;
