import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './CountryRow.css';

const countries = [
    { id: 1, name: 'Australia', code: 'au' },
    { id: 2, name: 'Brazil', code: 'br' },
    { id: 3, name: 'France', code: 'fr' },
    { id: 4, name: 'Germany', code: 'de' },
    { id: 5, name: 'Italy', code: 'it' },
    { id: 6, name: 'Japan', code: 'jp' },
    { id: 7, name: 'Mexico', code: 'mx' },
    { id: 8, name: 'Netherlands', code: 'nl' },
    { id: 9, name: 'South Korea', code: 'kr' },
    { id: 10, name: 'Taiwan', code: 'tw' },
    { id: 11, name: 'Thailand', code: 'th' },
    { id: 12, name: 'United States', code: 'us' }
];

const CountryRow = () => {
    const [visibleCountries, setVisibleCountries] = useState(countries.length); // Start by showing all
    const countryRowRef = useRef(null);
    const navigate = useNavigate();
    const [loadedImages, setLoadedImages] = useState({}); // Track which images are loaded

    // Calculate how many countries can fit in the current container width
    const calculateVisibleCountries = () => {
        if (countryRowRef.current) {
            const containerWidth = countryRowRef.current.offsetWidth;
            const itemWidth = 180;
            const visibleItems = Math.floor(containerWidth / itemWidth);
            setVisibleCountries(visibleItems);
        }
    };

    // Handle country click to navigate to the appropriate path
    const handleCountryClick = (countryName) => {
        const formattedName = countryName
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join('-');
        navigate(`/browse/${formattedName}`);
    };

    useEffect(() => {
        calculateVisibleCountries();
        window.addEventListener('resize', calculateVisibleCountries);

        // Cleanup event listener on unmount
        return () => window.removeEventListener('resize', calculateVisibleCountries);
    }, []);

    // Handle "View All" button click
    const handleViewAllClick = () => {
        navigate('/browse');
    };

    // Handle image load to remove the blur
    const handleImageLoad = (countryId) => {
        setLoadedImages((prevLoadedImages) => ({
            ...prevLoadedImages,
            [countryId]: true,
        }));
    };

    return (
        <div className="country-section-wrapper">
            <div className="country-header-wrapper">
                <h2 className="country-row-title"><span className="earf-blue">Top</span> Live Countries</h2>
                <button className="country-view-all-btn" onClick={handleViewAllClick}>View All</button>
            </div>
            <div className="country-row-wrapper" ref={countryRowRef}>
                {countries.slice(0, visibleCountries).map((country) => (
                    <div
                        className="country-item-wrapper"
                        key={country.id}
                        onClick={() => handleCountryClick(country.name)}
                    >
                        <div className="country-flag-wrapper">
                            <img
                                src={`https://flagcdn.com/w320/${country.code}.png`}
                                alt={country.name}
                                className={`country-flag ${loadedImages[country.id] ? 'loaded' : 'loading'}`}
                                loading="lazy"
                                onLoad={() => handleImageLoad(country.id)}
                            />
                            {/* Blur effect placeholder */}
                            {!loadedImages[country.id] && <div className="image-placeholder"></div>}
                        </div>
                        <span className="country-name">{country.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CountryRow;
