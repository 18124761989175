import React, { useState, useEffect, useRef } from 'react';
import Hls from 'hls.js';
import { usePlayerContext } from '../context/PlayerContext';
import styles from './AudioPlayer.module.css'; // Import the CSS module

const AudioPlayer = () => {
  const { currentStation, stopStation } = usePlayerContext();
  const audioPlayerRef = useRef(null);
  const stationNameRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const hlsRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const fallbackImage = "/assets/Speaker_Icon_grey.png"; // Updated to public folder path
  // Effect to handle audio playing when station changes or isPlaying changes
  useEffect(() => {
    if (currentStation && currentStation.isPlaying) {
      playStream(currentStation.url); // Play the stream when the station is active
    }

    // Cleanup on component unmount
    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, [currentStation]);

  // Function to handle playing the stream (HLS or regular)
  const playStream = (url) => {
    // Clear the existing source to ensure a fresh connection
    audioPlayerRef.current.src = '';
    if (hlsRef.current) {
      hlsRef.current.destroy(); // Clean up previous HLS instance
      hlsRef.current = null;
    }

    // Check for HLS Stream
    if (url.endsWith('.m3u8') || url.includes('.m3u8?')) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hlsRef.current = hls;
        hls.loadSource(url);
        hls.attachMedia(audioPlayerRef.current);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          audioPlayerRef.current.play().then(() => setIsPlaying(true)).catch((error) => {
            console.error('Error playing audio:', error);
            setIsPlaying(false);
          });
        });
      } else {
        console.error('HLS not supported in this browser.');
      }
    } 
    // Directly supported formats by HTML5 audio
    else {
      audioPlayerRef.current.src = url;
      audioPlayerRef.current.play().then(() => setIsPlaying(true)).catch((error) => {
        console.error('Error playing audio:', error);
        setIsPlaying(false);
      });
    }
  };

  // Effect to handle scrolling condition based on overflow
  useEffect(() => {
    if (stationNameRef.current) {
      const shouldScroll = stationNameRef.current.scrollWidth > stationNameRef.current.clientWidth;
      setIsScrolling(shouldScroll);
    }
  }, [currentStation]);

  // Play or reconnect to the stream
  const handlePlayPause = () => {
    if (isPlaying) {
      audioPlayerRef.current.pause();
      setIsPlaying(false);
    } else {
      playStream(currentStation.url); // Reconnect and play the stream
    }
  };

  // Stop the station completely
  const handleStop = () => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.pause();
      audioPlayerRef.current.src = ""; // Clear the audio source
    }
    if (hlsRef.current) {
      hlsRef.current.destroy();
      hlsRef.current = null;
    }
    setIsPlaying(false);
    stopStation(); // Remove the current station from context (hide the player)
  };

  return (
    currentStation && currentStation.isPlaying && (
      <div className={styles['audio-player-header']}>
        <img 
          src={currentStation.favicon || fallbackImage} 
          alt={currentStation.name} 
          className={styles['favicon-icon']}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = fallbackImage; 
          }}
        />
        <div className={styles['station-info-header']}>
          <span
            ref={stationNameRef}
            className={isScrolling ? styles.scroll : ''}
          >
            {currentStation.name}
          </span>
        </div>
        <button onClick={handlePlayPause} className={styles['play-pause-button']}>
          <i className={`fas ${isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
        </button>
        <button onClick={handleStop} className={styles['stop-button']}>
          <i className="fas fa-stop"></i>
        </button>
        <audio ref={audioPlayerRef} />
      </div>
    )
  );
};

export default AudioPlayer;
