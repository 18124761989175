import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Correct import from react-router-dom
import './Changelog.css';
import { Helmet } from 'react-helmet-async';

const Changelog = () => {
  // An array of changelog entries, each with a title, date, and details.
  const changelogs = [
    {
      title: 'Version 0.3.0',
      date: 'November 14, 2024',
      changes: [
        'Enhanced the "Location Infofrmation" component to display local weather with both Celsius and Fahrenheit temperatures and auto-updating local time based on the selected coordinates.',
        'Optimized data fetching for local time and weather based on coordinates to enhance the real-time experience for users viewing location-based streams.',
      ],
    },
    {
      title: 'Version 0.2.0',
      date: 'November 10, 2024',
      changes: [
        'Introduced an enhanced stream description feature that provides detailed information about locations using data from various sources.',
        'Added a new "Feature Tags" section within the stream description to highlight key attributes of locations.',
        'Improved the display of content descriptions to provide more relevant and comprehensive details.',
        'Enhanced the user interface to seamlessly integrate with real-time location data and streamline the exploration experience.',
        'Introduced a "Similar Locations" component that fetches and displays related places based on tags, Wikidata information, or other relevant attributes.',
        'Minor performance improvements to ensure faster and more responsive interactions when browsing locations.',
      ],
    },
    {
      title: 'Version 0.1.0',
      date: 'October 9, 2024',
      changes: [
        'Hello World :)',
      ],
    },
  ];
  


  // State to track which changelog entry is expanded
  const [expandedIndex, setExpandedIndex] = useState(null);
  const navigate = useNavigate(); // Corrected import for useNavigate

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleBackClick = () => {
    navigate(-1); // Navigates to the previous page
  };

  return (

    <div className="changelog-container">

      <Helmet prioritizeSeoTags>
        {/* Page Title */}
        <title>Changelog | Earf</title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Stay updated with the latest features, improvements, and updates on Earf. See what's new and how we're evolving the platform to enhance your experience."
        />

        {/* Canonical Tag */}
        <link rel="canonical" href="https://earf.tv/changelog" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Changelog | Earf" />
        <meta
          property="og:description"
          content="Discover the latest changes, new features, and improvements on Earf. Stay informed on how we're continuously enhancing the platform for a better user experience."
        />
        <meta property="og:image" content="https://earf.tv/metaimage.png" />
        <meta property="og:url" content="https://earf.tv/changelog" />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Changelog | Earf" />
        <meta
          name="twitter:description"
          content="Get the latest updates on Earf. Explore new features, improvements, and enhancements designed to improve your live streaming experience."
        />
        <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
        <meta name="twitter:image:alt" content="Earf Changelog" />
      </Helmet>


      {/* Header Section with Back Button and Title */}
      <div className="changelog-header-container">
        <h1 className="changelog-title">Changelog</h1>
        <button onClick={handleBackClick} className="back-button">
          &larr; Back
        </button>
      </div>

      {changelogs.map((log, index) => (
        <div key={index} className="changelog-entry">
          <div className="changelog-header" onClick={() => toggleExpand(index)}>
            <h3>{log.title}</h3>
            <p>{log.date}</p>
            <button className="expand-btn">
              {expandedIndex === index ? 'Collapse' : 'Expand'}
            </button>
          </div>
          {expandedIndex === index && (
            <div className="changelog-details">
              <ul>
                {log.changes.map((change, idx) => (
                  <li key={idx}>{change}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Changelog;
