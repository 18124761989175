import React from 'react';
import './index.css';
import App from './App';
import { PlayerProvider } from './components/context/PlayerContext';
import { BrowserRouter as Router } from 'react-router-dom'; 
import ErrorBoundary from './components/ErrorBoundary';
import { hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

const container = document.getElementById('root');

hydrateRoot(
  container,
  <React.StrictMode>
    <ErrorBoundary>
      <HelmetProvider> 
        <Router>
          <PlayerProvider>
            <App />
          </PlayerProvider>
        </Router>
      </HelmetProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
