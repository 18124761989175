import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import LocationInfo from '../LocationInfo/LocationInfo';
import StreamDescription from '../StreamDescription/StreamDescription';
import './VideoView.css';
import SimilarVideosSidebar from '../SimilarVideosSidebar/SimilarVideosSidebar';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga4';

const VideoView = ({
  feature,
  videoDetails,
  channelDetails,
  setIsMiniPlayerActive,
  setMiniPlayerFeature,
  handleBackClick,
  setMapState,
}) => {
  const { videoId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [localFeature, setLocalFeature] = useState(feature);
  const [localVideoDetails, setLocalVideoDetails] = useState(videoDetails);
  const [localChannelDetails, setLocalChannelDetails] = useState(channelDetails);
  const [placeQid, setPlaceQid] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    const fetchFeatureAndDetails = async () => {
      try {
        let foundFeature = feature || null;

        if (!feature) {
          const liveResponse = await fetch('https://server.earf.tv/live-cameras', {
            headers: {
              'x-api-key': process.env.REACT_APP_EARF_API_KEY,
              'Content-Type': 'application/json',
            },
          });
          const liveData = await liveResponse.json();
          foundFeature = liveData.features.find((f) => f.properties.videoId === videoId);

          if (!foundFeature) {
            const browseResponse = await fetch('https://server.earf.tv/browse-cameras', {
              headers: {
                'x-api-key': process.env.REACT_APP_EARF_API_KEY,
                'Content-Type': 'application/json',
              },
            });
            const browseData = await browseResponse.json();
            foundFeature = browseData.features.find((f) => f.properties.videoId === videoId);
          }
        }

        if (foundFeature) {
          setLocalFeature(foundFeature);

          // Extract QID
          const qid = `${foundFeature.properties.qid}`;
          setPlaceQid(qid);
          // Extract country code
          const code = foundFeature.properties.country_code;
          setCountryCode(code);
        }

        if (!videoDetails) {
          const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
          const videoDetailsUrl = `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${googleApiKey}`;
          const videoResponse = await fetch(videoDetailsUrl);
          const videoData = await videoResponse.json();

          if (videoData.items.length > 0) {
            setLocalVideoDetails(videoData.items[0].snippet);

            const channelId = videoData.items[0].snippet.channelId;
            const channelApiUrl = `https://www.googleapis.com/youtube/v3/channels?part=snippet&id=${channelId}&key=${googleApiKey}`;
            const channelResponse = await fetch(channelApiUrl);
            const channelData = await channelResponse.json();
            setLocalChannelDetails(channelData.items[0].snippet);
          } else {
            navigate('/404');
            return;
          }
        }
      } catch (error) {
        console.error('Error fetching details:', error);
        navigate('/404');
      } finally {
        setIsLoading(false);
      }
    };

    if (!isMobile) {
      setIsMiniPlayerActive(false);
      setMiniPlayerFeature(null);
    }

    fetchFeatureAndDetails();
  }, [feature, videoDetails, videoId, isMobile, setIsMiniPlayerActive, setMiniPlayerFeature]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleVideoPlay = () => {
    ReactGA.event({
      category: 'Video',
      action: 'Play',
      label: videoId,
    });
  };

  const handleBackNavigation = () => {
    if (location.state?.fromSidebar) {
      const tag = location.state.tag ? encodeURIComponent(location.state.tag) : '';
      navigate(`/browse/${tag}`);
    } else if (location.state?.fromBrowse) {
      if (!isMobile && localFeature) {
        setIsMiniPlayerActive(true);
        setMiniPlayerFeature(localFeature);
      }
      const tag = location.state.tag ? encodeURIComponent(location.state.tag) : '';
      navigate(`/browse/${tag}`);
    } else if (location.key !== 'default') {
      navigate(-1);
    } else {
      if (localFeature) {
        const coordinates = localFeature.geometry.coordinates;
        setMapState({
          center: [coordinates[0], coordinates[1]],
          zoom: 15,
        });
      }
      navigate(`/`);
      if (!isMobile && localFeature) {
        setIsMiniPlayerActive(true);
        setMiniPlayerFeature(localFeature);
      }
    }
  };

  const handleTagClick = (tag) => {
    navigate(`/browse/${encodeURIComponent(tag)}`);
  };

  if (isLoading) {
    return (
      <div className="full-container">
        <div className="spinner-wrapper">
          <div className="spinner"></div>
        </div>
      </div>
    );
  }

  const coordinates = localFeature?.geometry?.coordinates || null;
  const featureName = localFeature?.properties?.name || 'Stream';
  const videoThumbnail = `https://img.youtube.com/vi/${localFeature?.properties?.videoId}/hqdefault.jpg`;

  return (
    <>
      {localFeature && (
        <>
          <Helmet prioritizeSeoTags>
            <title>{`${featureName} Stream | Earf`}</title>
            <meta
              name="description"
              content={`Watch the live stream of ${featureName}, explore breathtaking locations in ${localFeature?.properties?.wikidata || 'this location'}, and discover more incredible live streams from around the world on Earf.`}
            />
            <link rel="canonical" href={`https://earf.tv/stream/${videoId}`} />
            <meta property="og:title" content={`${featureName} Stream | Earf`} />
            <meta
              property="og:description"
              content={`Watch the live stream of ${featureName}, explore breathtaking locations in ${localFeature?.properties?.wikidata || 'this location'}, and discover more incredible live streams from around the world on Earf.`}
            />
            <meta property="og:image" content={videoThumbnail} />
            <meta property="og:image:alt" content={`${featureName} thumbnail`} />
            <meta property="og:url" content={`https://earf.tv/stream/${videoId}`} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={`${featureName} Stream | Earf`} />
            <meta
              name="twitter:description"
              content={`Watch the live stream of ${featureName} and explore more live streams from stunning places around the world on Earf.`}
            />
            <meta name="twitter:image" content={videoThumbnail} />
          </Helmet>
        </>
      )}

      <div className="video-view">
        <div className="main-video-content">
          <div className="video-container">
            <iframe
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=0`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              onLoad={handleVideoPlay}
            ></iframe>
          </div>

          <div className="video-info">
            {localVideoDetails && localChannelDetails && (
              <div className="info-container">
                <div className="info-left">
                  <div className="channel-info">
                    <img
                      id="channel-thumbnail"
                      src={localChannelDetails.thumbnails.default.url}
                      alt={localChannelDetails.title}
                    />
                    <div className="channel-details">
                      <h2 className="feature-title">{featureName}</h2>
                      <h4 id="channel-name">
                        <a
                          href={`https://www.youtube.com/channel/${localVideoDetails.channelId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {localChannelDetails.title}
                        </a>
                      </h4>
                      <p style={{ color: localFeature?.properties?.website ? 'inherit' : 'grey' }}>
                        {localFeature?.properties?.website ? (
                          <a
                            href={localFeature.properties.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="website-link"
                          >
                            Official Website
                          </a>
                        ) : (
                          'No Website Available'
                        )}
                      </p>
                      <p className="wikidataText">
                        {`${localFeature?.properties?.wikidata}`}
                      </p>

                      <div className="feature-tags">
                        {[localFeature?.properties?.tag1, localFeature?.properties?.tag2, localFeature?.properties?.tag3]
                          .filter((tag) => tag)
                          .map((tag, index) => (
                            <span
                              key={index}
                              className="feature-tag"
                              onClick={() => handleTagClick(tag)}
                            >
                              {tag}
                            </span>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="info-right">
                  {coordinates && <LocationInfo coordinates={coordinates} />}
                  {!isMobile && (
                    <button className="back-button" onClick={handleBackNavigation}>
                      Back
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Pass the QID directly to StreamDescription */}
          <StreamDescription qid={placeQid} countryCode={countryCode} />


          {isMobile && (
            <button className="mobile-back-button" onClick={handleBackNavigation}>
              &larr; Back
            </button>
          )}
        </div>

        <SimilarVideosSidebar
          wikidata={localFeature?.properties?.wikidata}
          tags={[
            localFeature?.properties?.tag1,
            localFeature?.properties?.tag2,
            localFeature?.properties?.tag3,
          ].filter(Boolean)}
          currentVideoId={localFeature?.properties?.videoId}
        />
      </div>
    </>
  );
};

export default VideoView;
