import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './Browse.css';
import { Helmet } from 'react-helmet-async';

const countryCodes = {
    'Afghanistan': 'af',
    'Albania': 'al',
    'Algeria': 'dz',
    'Andorra': 'ad',
    'Angola': 'ao',
    'Antigua and Barbuda': 'ag',
    'Argentina': 'ar',
    'Armenia': 'am',
    'Australia': 'au',
    'Austria': 'at',
    'Azerbaijan': 'az',
    'Bahamas': 'bs',
    'Bahrain': 'bh',
    'Bangladesh': 'bd',
    'Barbados': 'bb',
    'Belarus': 'by',
    'Belgium': 'be',
    'Belize': 'bz',
    'Benin': 'bj',
    'Bhutan': 'bt',
    'Bolivia': 'bo',
    'Bosnia and Herzegovina': 'ba',
    'Botswana': 'bw',
    'Brazil': 'br',
    'Brunei Darussalam': 'bn',
    'Bulgaria': 'bg',
    'Burkina Faso': 'bf',
    'Burundi': 'bi',
    'Cabo Verde': 'cv',
    'Cambodia': 'kh',
    'Cameroon': 'cm',
    'Canada': 'ca',
    'Central African Republic': 'cf',
    'Chad': 'td',
    'Chile': 'cl',
    'China': 'cn',
    'Colombia': 'co',
    'Comoros': 'km',
    'Congo (Congo-Brazzaville)': 'cg',
    'Congo (Democratic Republic)': 'cd',
    'Costa Rica': 'cr',
    'Croatia': 'hr',
    'Cuba': 'cu',
    'Curaçao': 'cw',
    'Cyprus': 'cy',
    'Czech Republic': 'cz',
    'Denmark': 'dk',
    'Djibouti': 'dj',
    'Dominica': 'dm',
    'Dominican Republic': 'do',
    'Ecuador': 'ec',
    'Egypt': 'eg',
    'El Salvador': 'sv',
    'Equatorial Guinea': 'gq',
    'Eritrea': 'er',
    'Estonia': 'ee',
    'Eswatini': 'sz',
    'Ethiopia': 'et',
    'Fiji': 'fj',
    'Finland': 'fi',
    'France': 'fr',
    'Gabon': 'ga',
    'Gambia': 'gm',
    'Georgia': 'ge',
    'Germany': 'de',
    'Ghana': 'gh',
    'Greece': 'gr',
    'Greenland': 'gl',  // Added Greenland
    'Grenada': 'gd',
    'Guatemala': 'gt',
    'Guinea': 'gn',
    'Guinea-Bissau': 'gw',
    'Guyana': 'gy',
    'Haiti': 'ht',
    'Honduras': 'hn',
    'Hungary': 'hu',
    'Iceland': 'is',
    'India': 'in',
    'Indonesia': 'id',
    'Iran': 'ir',
    'Iraq': 'iq',
    'Ireland': 'ie',
    'Israel': 'il',
    'Italy': 'it',
    'Jamaica': 'jm',
    'Japan': 'jp',
    'Jordan': 'jo',
    'Kazakhstan': 'kz',
    'Kenya': 'ke',
    'Kiribati': 'ki',
    'Kuwait': 'kw',
    'Kyrgyzstan': 'kg',
    'Laos': 'la',
    'Latvia': 'lv',
    'Lebanon': 'lb',
    'Lesotho': 'ls',
    'Liberia': 'lr',
    'Libya': 'ly',
    'Liechtenstein': 'li',
    'Lithuania': 'lt',
    'Luxembourg': 'lu',
    'Madagascar': 'mg',
    'Malawi': 'mw',
    'Malaysia': 'my',
    'Maldives': 'mv',
    'Mali': 'ml',
    'Malta': 'mt',
    'Marshall Islands': 'mh',
    'Mauritania': 'mr',
    'Mauritius': 'mu',
    'Mexico': 'mx',
    'Micronesia': 'fm',
    'Moldova': 'md',
    'Monaco': 'mc',
    'Mongolia': 'mn',
    'Montenegro': 'me',
    'Morocco': 'ma',
    'Mozambique': 'mz',
    'Myanmar': 'mm',
    'Namibia': 'na',
    'Nauru': 'nr',
    'Nepal': 'np',
    'Netherlands': 'nl',
    'New Zealand': 'nz',
    'Nicaragua': 'ni',
    'Niger': 'ne',
    'Nigeria': 'ng',
    'North Korea': 'kp',
    'North Macedonia': 'mk',
    'Norway': 'no',
    'Oman': 'om',
    'Pakistan': 'pk',
    'Palau': 'pw',
    'Panama': 'pa',
    'Papua New Guinea': 'pg',
    'Paraguay': 'py',
    'Peru': 'pe',
    'Philippines': 'ph',
    'Poland': 'pl',
    'Portugal': 'pt',
    'Qatar': 'qa',
    'Romania': 'ro',
    'Russia': 'ru',
    'Rwanda': 'rw',
    'Saint Kitts and Nevis': 'kn',
    'Saint Lucia': 'lc',
    'Saint Vincent and the Grenadines': 'vc',
    'Samoa': 'ws',
    'San Marino': 'sm',
    'Sao Tome and Principe': 'st',
    'Saudi Arabia': 'sa',
    'Senegal': 'sn',
    'Serbia': 'rs',
    'Seychelles': 'sc',
    'Sierra Leone': 'sl',
    'Singapore': 'sg',
    'Slovakia': 'sk',
    'Slovenia': 'si',
    'Solomon Islands': 'sb',
    'Somalia': 'so',
    'South Africa': 'za',
    'South Korea': 'kr',
    'South Sudan': 'ss',
    'Spain': 'es',
    'Sri Lanka': 'lk',
    'Sudan': 'sd',
    'Suriname': 'sr',
    'Sweden': 'se',
    'Switzerland': 'ch',
    'Syria': 'sy',
    'Taiwan': 'tw',
    'Tajikistan': 'tj',
    'Tanzania': 'tz',
    'Thailand': 'th',
    'Timor-Leste': 'tl',
    'Togo': 'tg',
    'Tonga': 'to',
    'Trinidad and Tobago': 'tt',
    'Tunisia': 'tn',
    'Turkey': 'tr',
    'Turkmenistan': 'tm',
    'Tuvalu': 'tv',
    'Uganda': 'ug',
    'Ukraine': 'ua',
    'United Arab Emirates': 'ae',
    'United Kingdom': 'gb',
    'United States': 'us',
    'Uruguay': 'uy',
    'Uzbekistan': 'uz',
    'Vanuatu': 'vu',
    'Vatican City': 'va',
    'Venezuela': 've',
    'Vietnam': 'vn',
    'Yemen': 'ye',
    'Zambia': 'zm',
    'Zimbabwe': 'zw',
    'Palestine': 'ps',
    'Western Sahara': 'eh',
    'Hong Kong': 'hk',
    'Macau': 'mo',
    'Faroe Islands': 'fo',
    'Puerto Rico': 'pr',
    'Kosovo': 'xk',
    'Montserrat': 'ms',
    'Bermuda': 'bm',
    'Guam': 'gu',
    'Falkland Islands': 'fk',
    'French Polynesia': 'pf',
    'New Caledonia': 'nc',
    'Saint Pierre and Miquelon': 'pm',
    'Gibraltar': 'gi'
};




// Placeholder thumbnails for non-country tags
const tagThumbnails = {
    'North America': '/Browse-Assets/NA.jpg',
    'Weather': '/Browse-Assets/Weather.webp',
};

const Browse = () => {
    const [tags, setTags] = useState([]);
    const [filteredTags, setFilteredTags] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [features, setFeatures] = useState([]);
    const [filteredFeatures, setFilteredFeatures] = useState([]);
    const [selectedTag, setSelectedTag] = useState(null);
    const [liveViewers, setLiveViewers] = useState({});
    const [isMobile, setIsMobile] = useState(false);
    const [tagFeatureCounts, setTagFeatureCounts] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [featuresLoading, setFeaturesLoading] = useState(false);
    const navigate = useNavigate();
    const { tag: paramTag, videoId: paramVideoId } = useParams();
    const [isMiniPlayerActive, setIsMiniPlayerActive] = useState(false);
    const [miniPlayerFeature, setMiniPlayerFeature] = useState(null);
    const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const apiKey = process.env.REACT_APP_EARF_API_KEY;
    const [isLoaded, setIsLoaded] = useState(false); // Track image loading state


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set the initial state

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    // Fetch features and tags data from GeoJSON files on initial load
    useEffect(() => {
        const fetchFeaturesAndTags = async () => {
            try {
                setIsLoading(true); // Show loader while fetching
                const [liveCamerasResponse, browseCamerasResponse] = await Promise.all([
                    fetch('https://server.earf.tv/live-cameras', {
                        headers: {
                            'x-api-key': apiKey,
                            'Content-Type': 'application/json',
                        },
                    }),
                    fetch('https://server.earf.tv/browse-cameras', {
                        headers: {
                            'x-api-key': apiKey,
                            'Content-Type': 'application/json',
                        },
                    }),
                ]);

                if (!liveCamerasResponse.ok || !browseCamerasResponse.ok) {
                    throw new Error('Failed to fetch feature data.');
                }

                const liveCamerasData = await liveCamerasResponse.json();
                const browseCamerasData = await browseCamerasResponse.json();
                const allFeatures = [...liveCamerasData.features, ...browseCamerasData.features];
                setFeatures(allFeatures);

                const uniqueTags = new Set();
                const tagCounts = {};

                allFeatures.forEach((feature) => {
                    ['tag1', 'tag2', 'tag3'].forEach((tagKey) => {
                        const tag = feature.properties[tagKey];
                        if (tag) {
                            uniqueTags.add(tag);
                            tagCounts[tag] = (tagCounts[tag] || 0) + 1;
                        }
                    });
                });

                const sortedTags = [...uniqueTags].sort((a, b) => {
                    const aIsCountry = countryCodes.hasOwnProperty(a);
                    const bIsCountry = countryCodes.hasOwnProperty(b);
                    if (aIsCountry && !bIsCountry) return -1;
                    if (!aIsCountry && bIsCountry) return 1;
                    return a.localeCompare(b);
                });

                setTags(sortedTags);
                setFilteredTags(sortedTags); // Set initial tags to be the full list
                setTagFeatureCounts(tagCounts);
            } catch (error) {
                console.error('Error fetching GeoJSON:', error);
            } finally {
                setIsLoading(false); // Hide loader after fetching is done
            }
        };

        fetchFeaturesAndTags();
    }, [apiKey]);


    // Handle tag selection based on URL parameter
    useEffect(() => {
        if (paramTag && features.length > 0) {
            const decodedTag = decodeURIComponent(paramTag).replace(/-/g, ' ');
            handleTagClick(decodedTag, false);
        }
    }, [paramTag, features]);

    // Handle search for tags and features
    const handleSearchChange = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        if (!selectedTag) {
            // Filter tags if no tag is selected
            const filtered = tags.filter(tag => tag.toLowerCase().includes(query));
            setFilteredTags(filtered);
        } else {
            // Filter the already filtered features (within the selected tag's features)
            const filtered = filteredFeatures.filter((feature) =>
                feature.properties.name.toLowerCase().includes(query)
            );
            setFilteredFeatures(filtered);
        }
    };

    const handleTagClick = async (tag, navigateToTag = true) => {
        const normalizedTag = tag.toLowerCase(); // Normalize the tag to lowercase for comparison
        let matchingTag = tags.find((t) => t.toLowerCase() === normalizedTag); // Find the matching tag in the list

        if (!matchingTag) {
            // If no matching tag is found, navigate to 404 page
            navigate('/404');
            return; // Exit the function to prevent further processing
        }

        // Use the correct capitalization from `matchingTag`
        setSelectedTag(matchingTag);

        if (navigateToTag) {
            const formattedTag = matchingTag.replace(/\s+/g, '-');
            navigate(`/browse/${encodeURIComponent(formattedTag)}`);
        }

        setFeaturesLoading(true); // Show loader for features

        const filtered = features.filter(
            (feature) =>
                feature.properties.tag1 === matchingTag ||
                feature.properties.tag2 === matchingTag ||
                feature.properties.tag3 === matchingTag
        );

        if (filtered.length === 0) {
            // If no features match the tag, navigate to 404 page
            navigate('/404');
            return; // Exit the function to prevent further processing
        }

        const liveViewersCounts = {};
        const availableStreams = [];

        await Promise.all(
            filtered.map(async (feature) => {
                const videoId = feature.properties.videoId;
                try {
                    const url = `https://www.googleapis.com/youtube/v3/videos?part=snippet,liveStreamingDetails&id=${videoId}&key=${googleApiKey}`;
                    const response = await fetch(url);
                    const data = await response.json();

                    if (data.items.length > 0) {
                        const videoItem = data.items[0];
                        const liveStreamingDetails = videoItem.liveStreamingDetails;
                        const broadcastContent = videoItem.snippet.liveBroadcastContent;

                        if (broadcastContent === 'live' || broadcastContent === 'upcoming') {
                            availableStreams.push(feature);

                            if (liveStreamingDetails && liveStreamingDetails.concurrentViewers) {
                                liveViewersCounts[videoId] = parseInt(liveStreamingDetails.concurrentViewers, 10);
                            }
                        }
                    }
                } catch (error) {
                    console.error('Error fetching YouTube video data:', error);
                }
            })
        );

        setLiveViewers(liveViewersCounts);

        const sortedFiltered = availableStreams.sort((a, b) => {
            const viewersA = liveViewersCounts[a.properties.videoId] || 0;
            const viewersB = liveViewersCounts[b.properties.videoId] || 0;
            return viewersB - viewersA;
        });

        setFilteredFeatures(sortedFiltered); // Set filtered features for the selected tag
        setFeaturesLoading(false); // Hide loader for features
    };
    const handleImageLoad = () => {
        setIsLoaded(true); // Set loaded to true when the image is fully loaded
    };

    const handleFeatureClick = (feature) => {
        setIsMiniPlayerActive(false); // Deactivate mini-player when navigating to a full video
        setMiniPlayerFeature(null); // Clear the mini-player feature

        navigate(`/stream/${feature.properties.videoId}`, {
            state: {
                fromBrowse: true,
                tag: selectedTag,
                feature: feature,
            },
        });
    };
    // Update the handleBack function to navigate back and update the URL:
    const handleBackClick = () => {
        setSelectedTag(null); // Clear the selected tag
        navigate('/browse'); // Update the URL back to /browse
    };

    return (
        <div className="browse-container">
            <Helmet prioritizeSeoTags>
                {/* Dynamic Page Title */}
                <title>{selectedTag ? `${selectedTag} Streams | Earf` : 'Browse Streams | Earf'}</title>

                {/* Dynamic Meta Description */}
                <meta
                    name="description"
                    content={selectedTag ? `Discover live streams in ${selectedTag} on Earf. Watch and explore unique locations with real-time cameras.` : 'Browse live streams from around the globe on Earf. Watch real-time views of some of the most fascinating places on Earth.'}
                />

                {/* Canonical Tag */}
                <link rel="canonical" href={selectedTag ? `https://earf.tv/browse/${selectedTag}` : 'https://earf.tv/browse'} />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={selectedTag ? `${selectedTag} Streams | Earf` : 'Browse Streams | Earf'} />
                <meta
                    property="og:description"
                    content={selectedTag ? `Watch live streams from ${selectedTag} on Earf. Explore real-time views of various locations and experience the world like never before.` : 'Browse live streams on Earf and explore real-time views from all over the world. Experience the beauty of Earth’s unique places through live cameras.'}
                />

                {/* Dynamically change the Open Graph image to use country flags if available */}
                <meta
                    property="og:image"
                    content={selectedTag && countryCodes[selectedTag]
                        ? `https://flagcdn.com/w320/${countryCodes[selectedTag]}.png`
                        : 'https://earf.tv/metaimage.png'}
                />
                <meta property="og:url" content={selectedTag ? `https://earf.tv/browse/${selectedTag}` : 'https://earf.tv/browse'} />
                <meta property="og:type" content="website" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content={selectedTag ? `${selectedTag} Streams | Earf` : 'Browse Streams | Earf'} />
                <meta
                    name="twitter:description"
                    content={selectedTag ? `Explore live streams from ${selectedTag} on Earf. Watch real-time cameras of amazing places and see the world as it happens.` : 'Discover live streams from all over the world on Earf. Watch and explore fascinating real-time views of beautiful locations.'}
                />

                {/* Dynamically change the Twitter image to use country flags if available */}
                <meta
                    name="twitter:image"
                    content={selectedTag && countryCodes[selectedTag]
                        ? `https://flagcdn.com/w320/${countryCodes[selectedTag]}.png`
                        : 'https://earf.tv/metaimage.png'}
                />
                <meta name="twitter:image:alt" content="Earf Logo" />
            </Helmet>


            {/* Display the Spinner while both tags and features are loading */}
            {isLoading || featuresLoading ? (
                <div className="full-container">
                    <div className="spinner-wrapper">
                        <div className="spinner"></div> {/* Spinner Element */}
                    </div>
                </div>

            ) : (
                <>
                    {/* Tag View */}
                    {!selectedTag && (
                        <div className="tag-view">
                            <div className="tag-header">
                                <h2 className="left-header">
                                    Browse <span style={{ color: 'rgba(104, 223, 223, 0.733)' }}>Cameras</span>
                                </h2>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="search-input"
                                />
                            </div>
                            <div className="tag-list-container">
                                <div className="tag-list">
                                    {filteredTags.map((tag, index) => (
                                        <div key={index} className="tag-item" onClick={() => handleTagClick(tag)}>
                                            <div className="tag-thumbnail">
                                                <img
                                                    src={countryCodes[tag]
                                                        ? `https://flagcdn.com/w320/${countryCodes[tag]}.png`
                                                        : tagThumbnails[tag] || '/placeholder.webp'}
                                                    alt={`${tag} thumbnail`}
                                                    className={`tag-thumbnail-img ${isLoaded ? 'loaded' : 'loading'}`} // Dynamically set class
                                                    loading="lazy" // Enable lazy loading
                                                    onLoad={handleImageLoad} // Remove blur when the image loads
                                                />
                                                {/* Placeholder that shows blur until the image is fully loaded */}
                                                {!isLoaded && <div className="image-placeholder"></div>}
                                            </div>

                                            <div className="tag-info">
                                                <div className="tag-name">{tag}</div>
                                                <div className="tag-count">
                                                    {tagFeatureCounts[tag] || 0} {tagFeatureCounts[tag] === 1 ? 'Stream' : 'Streams'}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Feature View */}
                    {selectedTag && (
                        <div className="feature-view">
                            <div className="feature-header">
                                <h2 className="centered-header">
                                    {countryCodes[selectedTag] && (
                                        <img
                                            src={`https://flagcdn.com/w320/${countryCodes[selectedTag]}.png`}
                                            alt={`${selectedTag} flag`}
                                            className="country-flag-title"
                                        />
                                    )}
                                    {selectedTag} <span style={{ marginLeft: '5px', color: 'rgba(104, 223, 223, 0.733)' }}>Live</span>
                                </h2>
                                <button className="back-button" onClick={handleBackClick}>
                                   Back
                                </button>
                            </div>
                            <div className="feature-list-container">
                                <div className="feature-list">
                                    {filteredFeatures.map((feature, index) => (
                                        <div key={index} className="feature-item" onClick={() => handleFeatureClick(feature)}>
                                            <div className="feature-thumbnail">
                                                {/* Image with lazy loading and onLoad handler */}
                                                <img
                                                    src={`https://img.youtube.com/vi/${feature.properties.videoId}/hqdefault.jpg`}
                                                    alt={`${feature.properties.name} thumbnail`}
                                                    className={`feature-thumbnail-img ${isLoaded ? 'loaded' : 'loading'}`}
                                                    loading="lazy" // Enable lazy loading
                                                    onLoad={handleImageLoad} // Handle image load event
                                                />
                                                {/* Blur placeholder visible until the image is loaded */}
                                                {!isLoaded && <div className="image-placeholder"></div>}
                                                <div className="live-badge">LIVE</div>
                                                <div className="live-viewers">
                                                    {liveViewers[feature.properties.videoId]
                                                        ? `${liveViewers[feature.properties.videoId]} watching`
                                                        : '0 watching'}
                                                </div>
                                            </div>

                                            <div className="feature-info">
                                                <div className="feature-details">
                                                    <div className="feature-name">{feature.properties.name}</div>
                                                    <div className="feature-wikidata">
                                                        {feature.properties.wikidata || 'No Wikidata available'}
                                                    </div>
                                                    <div className="feature-tags">
                                                        {[feature.properties.tag1, feature.properties.tag2, feature.properties.tag3]
                                                            .filter(tag => tag)
                                                            .map((tag, index) => (
                                                                <span key={index} className="feature-tag">{tag}</span>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Browse;