import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './StreamCreator.module.css';
import { AdminContext } from '../../AdminContext';
import { Helmet } from 'react-helmet-async';
import Map, { Marker } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';

import axios from 'axios';
import 'mapbox-gl/dist/mapbox-gl.css'; // Import Mapbox CSS

const categories = {
  News: ['News', 'Live Events', 'Weather', 'Sports', 'Festivals'],
  Tours: ['Walking Tour', 'Driving Tour', 'Drone Tour'],
  Urban: ['Capital City', 'City', 'Village', 'Town', 'Urban', 'Skylines', 'Nightlife', 'Harbours', 'Train Stations', 'Railways', 'Island', 'Airports'],
  Nature: ['Wildlife', 'Beaches', 'Mountains', 'Countryside', 'Lakes', 'Ocean Views', 'Parks', 'Rainforests', 'Rivers', 'Waterfalls', 'Deserts', 'Coral Reefs', 'Farms', 'Volcanoes'],
  Other: ['Landmarks', 'Theme Parks', 'Cultural Sites', 'Historic Sites', 'Hidden Gems', 'Local Favorites', 'Iconic Views', 'Family-Friendly', 'Zoos', 'Aquariums', 'Botanical Gardens', 'Animal Sanctuaries', 'Ports']
};

const countries = [
  // Sovereign States
  'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Argentina', 'Armenia', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin',
  'Bhutan', 'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cambodia', 'Cameroon', 'Canada', 'Cape Verde', 'Central African Republic', 'Chad', 'Chile',
  'China', 'Colombia', 'Comoros', 'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czech Republic', 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'East Timor', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea',
  'Eritrea', 'Estonia', 'Eswatini', 'Ethiopia', 'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia', 'Germany', 'Ghana', 'Greece', 'Greenland', 'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau', 'Guyana',
  'Haiti', 'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel', 'Italy', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kosovo', 'Kuwait', 'Kyrgyzstan',
  'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania', 'Mauritius',
  'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Myanmar', 'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Korea',
  'North Macedonia', 'Norway', 'Oman', 'Pakistan', 'Palau', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar', 'Romania', 'Russia', 'Rwanda', 'Saint Kitts and Nevis',
  'Saint Lucia', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia',
  'Solomon Islands', 'Somalia', 'South Africa', 'South Korea', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria', 'Taiwan', 'Tajikistan', 'Tanzania', 'Thailand', 'Togo',
  'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Vatican City',
  'Venezuela', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe',

  // Dependent Territories, Autonomous Regions, and Special Entities
  'Greenland', 'Hong Kong', 'Macau', 'Puerto Rico', 'Guam', 'Bermuda', 'Faroe Islands', 'French Polynesia', 'New Caledonia', 'Falkland Islands', 'Saint Pierre and Miquelon', 'Gibraltar', 'Montserrat',
  'Curaçao', 'Aruba', 'Sint Maarten', 'Turks and Caicos Islands', 'British Virgin Islands', 'U.S. Virgin Islands', 'American Samoa', 'Northern Mariana Islands', 'Anguilla', 'Cayman Islands', 'Saint Helena',
  'Tokelau', 'Niue', 'Wallis and Futuna', 'Christmas Island', 'Cocos (Keeling) Islands', 'Cook Islands', 'Pitcairn Islands', 'Bouvet Island', 'South Georgia and the South Sandwich Islands',

  // Disputed Territories
  'Western Sahara', 'Palestine', 'Kosovo', 'Abkhazia', 'South Ossetia', 'Nagorno-Karabakh', 'Transnistria',

  // Regions often listed separately in specific contexts
  'Scotland', 'Wales', 'Northern Ireland', 'England', 'Bonaire', 'Svalbard', 'Åland Islands'
];

const geocodeTypes = [
  'place',         // General place name
  'poi',           // Points of interest (e.g., landmarks, attractions)
  'neighborhood',  // Neighborhoods
  'locality',      // Local city or town name
  'region',        // Larger geographic regions (e.g., states, provinces)
  'postcode',      // Postal codes
  'district',      // Administrative districts
  'country',       // Country name
  'address',       // Specific address or street name
  'landmark',      // Specific landmarks (often overlaps with poi)
  'county',        // County or equivalent
  'ocean',         // Ocean names
  'continent'      // Continent names
];

// U
const regions = [
  'North America', 'South America', 'Europe', 'Asia', 'Africa', 'Oceania', 'Middle East', 'Caribbean', 'Central America', 'Southeast Asia', 'Eastern Europe', 'Central Asia', 'Southern Africa', 'Northern Africa'
];

const StreamCreator = () => {
  const { isAdminAuthenticated } = useContext(AdminContext);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [videoId, setVideoId] = useState('');
  const [tag1, setTag1] = useState('');
  const [tag2, setTag2] = useState('');
  const [tag3, setTag3] = useState('');
  const [type, setType] = useState('place');
  const [wikidata, setWikidata] = useState('');
  const [qid, setQid] = useState('');
  const [website, setWebsite] = useState('');
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');
  const [geocode, setGeocode] = useState('place');
  const [countryCode, setCountryCode] = useState(''); // New state for country code
  const [cameraType, setCameraType] = useState('live-cameras');
  const [isJsonFieldVisible, setJsonFieldVisible] = useState(false);
  const [jsonInput, setJsonInput] = useState('');
  const [viewport, setViewport] = useState({
    longitude: -0.118092,
    latitude: 51.509865,
    zoom: 5,
  });

  useEffect(() => {
    if (!isAdminAuthenticated) {
      navigate('/admin/login');
    }
  }, [isAdminAuthenticated, navigate]);

  // Fetch QID, Wikidata, and Country Code based on coordinates and selected geocode type
  const fetchQidAndWikidata = async (lon, lat) => {
    const apiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?language=en&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;
    try {
      const response = await axios.get(apiUrl);
      const features = response.data.features;

      // Find feature that matches exactly the selected geocode type
      const preferredFeature = features.find((feature) => feature.place_type.includes(geocode));

      if (preferredFeature) {
        setQid(preferredFeature.properties.wikidata || '');
        setWikidata(preferredFeature.place_name || '');
      } else {
        setQid('');
        setWikidata('');
      }

      // Extract country code if available
      const countryFeature = features.find((f) => f.place_type.includes('country'));
      if (countryFeature) {
        setCountryCode(countryFeature.properties.short_code.toUpperCase());
      } else {
        setCountryCode('');
      }
    } catch (error) {
      console.error("Error fetching QID, Wikidata, and Country Code:", error);
      setQid('');
      setWikidata('');
      setCountryCode('');
    }
  };

  // Update QID, Wikidata, and Country Code whenever coordinates or geocode type are changed
  useEffect(() => {
    if (longitude && latitude) {
      fetchQidAndWikidata(longitude, latitude);
    }
  }, [longitude, latitude, geocode]);


  const handleJsonInput = () => {
    try {
      const parsedJson = JSON.parse(jsonInput);
      const { properties, geometry } = parsedJson;

      setName(properties.name || '');
      setVideoId(properties.videoId || '');
      setTag1(properties.tag1 || '');
      setTag2(properties.tag2 || '');
      setTag3(properties.tag3 || '');
      setType(properties.type || 'place');
      setWikidata(properties.wikidata || '');
      setQid(properties.qid || '');
      setWebsite(properties.website || '');
      setLongitude(geometry.coordinates[0] || '');
      setLatitude(geometry.coordinates[1] || '');
      setGeocode(properties.geocode || 'place');

      alert('Form successfully populated from JSON!');
      setJsonFieldVisible(false);
    } catch (error) {
      alert('Invalid JSON format. Please check your input.');
    }
  };

  const generateJson = async () => {
    if (!name || !videoId || !longitude || !latitude) {
      alert('Please fill in the required fields.');
      return;
    }

    const newFeature = {
      type: 'Feature',
      properties: {
        name,
        videoId,
        tag1,
        tag2,
        tag3,
        type,
        wikidata,
        qid,
        website,
        geocode,
        country_code: countryCode, // Include country code in the JSON
      },
      geometry: {
        coordinates: [parseFloat(longitude), parseFloat(latitude)],
        type: 'Point',
      }
    };

    const apiUrl = `https://server.earf.tv/${cameraType}`;

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_EARF_API_KEY,
        },
        body: JSON.stringify(newFeature),
      });

      if (!response.ok) {
        throw new Error('Failed to add new feature');
      }

      alert('Successfully added new feature!');
    } catch (error) {
      console.error('Error adding new feature:', error);
      alert('Failed to add new feature.');
    }
  };

  const adminMapClick = (event) => {
    const { lngLat } = event;
    setLongitude(lngLat.lng.toFixed(6));
    setLatitude(lngLat.lat.toFixed(6));
  };

  return (
    <div className={styles['feature-creator-container']}>
      <Helmet prioritizeSeoTags>
        <title>Feature Creator | Earf Admin</title>
        <meta name="description" content="Login to the Earf.TV admin panel to manage streams and settings." />
        <meta property="og:image" content="https://earf.tv/metaimage.png" />
        <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
      </Helmet>

      <div className={styles['header-container']}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/christmasearfy.png" alt="Earf.TV Logo" className={styles['logo']} />
          <h1 className={styles['page-title']}>Earf Feature Creator</h1>
        </div>
        <button className="back-button" onClick={() => navigate(-1)}>
          ← Back
        </button>
      </div>

      {/* Toggle JSON Input Field */}
      <div className={styles['form-section']}>
        <button onClick={() => setJsonFieldVisible(!isJsonFieldVisible)} className={styles['toggle-button-dark']}>
          {isJsonFieldVisible ? 'Hide Import' : 'Import Location'}
        </button>
        {isJsonFieldVisible && (
          <div className={styles['json-input-container']}>
            <textarea
              className={styles['json-textarea-dark']}
              value={jsonInput}
              onChange={(e) => setJsonInput(e.target.value)}
              placeholder="Paste feature JSON here..."
            />
            <button onClick={handleJsonInput} className={styles['parse-button-dark']}>
              Parse JSON
            </button>
          </div>
        )}
      </div>

      {/* General Info Section */}
      <div className={styles['form-container']}>
        <div className={styles['form-section']}>
          <h2>General Info</h2>

          <div className={styles['form-group']}>
            <label>Feature Name *</label>
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter feature name" required />
          </div>

          <div className={styles['form-group']}>
            <label>YouTube Video ID *</label>
            <input type="text" value={videoId} onChange={(e) => setVideoId(e.target.value)} placeholder="Enter video ID" required />
          </div>

          <div className={styles['form-group']}>
            <label>Feature Type *</label>
            <select value={type} onChange={(e) => setType(e.target.value)} required>
              {['place', 'airport', 'rail', 'animal', 'beach', 'news', 'walking', 'driving', 'drone'].map((type) => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>

          <div className={styles['form-group']}>
            <label>Website</label>
            <input type="url" value={website} onChange={(e) => setWebsite(e.target.value)} placeholder="Enter website" />
          </div>
        </div>

        {/* Location Info Section */}
        <div className={styles['form-section']}>
          <h2>Location Info</h2>
          <div className={styles['form-group']}>
            <label>Longitude</label>
            <input type="text" value={longitude} onChange={(e) => setLongitude(e.target.value)} placeholder="Enter longitude" />
          </div>

          <div className={styles['form-group']}>
            <label>Latitude</label>
            <input type="text" value={latitude} onChange={(e) => setLatitude(e.target.value)} placeholder="Enter latitude" />
          </div>

          <div className={styles['form-group']}>
            <label>QID</label>
            <input type="text" value={qid} onChange={(e) => setQid(e.target.value)} placeholder="Enter QID or auto-populated" />
          </div>


          <div className={styles['form-group']}>
            <label>Geocode Type *</label>
            <select value={geocode} onChange={(e) => setGeocode(e.target.value)} required>
              {geocodeTypes.map((code) => (
                <option key={code} value={code}>{code}</option>
              ))}
            </select>
          </div>

          <div className={styles['form-group']}>
            <label>Wikidata</label>
            <input type="text" value={wikidata} onChange={(e) => setWikidata(e.target.value)} placeholder="Enter wikidata" />
          </div>

          <div className={styles['form-group']}>
            <label>Country Code</label>
            <input type="text" value={countryCode} onChange={(e) => setCountryCode(e.target.value)} placeholder="Auto-populated country code" readOnly />
          </div>
        </div>

        {/* Tags Section */}
        <div className={styles['form-section']}>
          <h2>Tags</h2>
          <div className={styles['form-group']}>
            <label>Tag 1 *</label>
            <select value={tag1} onChange={(e) => setTag1(e.target.value)} required>
              <option value="">Select Tag 1</option>
              {Object.keys(categories).map((category) => (
                <optgroup label={category} key={category}>
                  {categories[category].map((tag) => (
                    <option key={tag} value={tag}>{tag}</option>
                  ))}
                </optgroup>
              ))}
            </select>
          </div>

          <div className={styles['form-group']}>
            <label>Tag 2 (Country) *</label>
            <select value={tag2} onChange={(e) => setTag2(e.target.value)} required>
              <option value="">Select Country</option>
              {countries.map((country) => (
                <option key={country} value={country}>{country}</option>
              ))}
            </select>
          </div>

          <div className={styles['form-group']}>
            <label>Tag 3 (Region) *</label>
            <select value={tag3} onChange={(e) => setTag3(e.target.value)} required>
              <option value="">Select Region</option>
              {regions.map((region) => (
                <option key={region} value={region}>{region}</option>
              ))}
            </select>
          </div>

          <button className={styles['generate-button']} onClick={generateJson}>
            Add to Server
          </button>
        </div>




        {/* Camera Type and Map Section */}
        <div className={styles['form-section']}>
          <div className={styles['form-group']}>
            <label>Select Camera Type *</label>
            <select value={cameraType} onChange={(e) => setCameraType(e.target.value)} required>
              <option value="live-cameras">Live Cameras</option>
              <option value="browse-cameras">Browse Cameras</option>
            </select>
          </div>

          <div style={{ height: '300px', marginBottom: '20px' }}>
            <Map
              {...viewport}
              width="100%"
              height="100%"
              mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
              mapStyle="mapbox://styles/mapbox/standard"
              onMove={(evt) => setViewport(evt.viewState)}
              onClick={adminMapClick}
              projection="globe" // Set projection to globe for a spherical view
            >
              {longitude && latitude && (
                <Marker longitude={parseFloat(longitude)} latitude={parseFloat(latitude)} />
              )}
            </Map>

          </div>
        </div>
      </div>
    </div>
  );
};

export default StreamCreator;