import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './FeatureRow.module.css';

const features = [
  {
    imgSrc: '/explore.webp',
    title: 'Explore',
    description: 'Explore an interactive map with live weather, earthquake alerts, and more. Stay updated on global events in one place.',
    route: '/explore',
  },
  {
    imgSrc: '/browsecamera.webp',
    title: 'Browse Cameras',
    description: 'View live streams from cameras worldwide. Search by location or explore scenic views of cities, nature, and more.',
    route: '/browse',
  },
  {
    imgSrc: '/browseradio.webp',
    title: 'Browse Radio',
    description: 'Listen to live radio from around the world. Discover music, news, and culture from different regions globally.',
    route: '/radio',
  },
  {
    imgSrc: '/livealerts.webp',
    title: 'Live Alerts',
    description: 'Get real-time alerts for weather, earthquakes, global events, and updates on the FBI’s most wanted lists.',
    route: '/live-alerts',
  }
];

const FeatureRow = () => {
  const navigate = useNavigate();
  const [loadedImages, setLoadedImages] = useState({}); // Track loaded images

  // Handle image load to remove blur
  const handleImageLoad = (index) => {
    setLoadedImages((prevLoadedImages) => ({
      ...prevLoadedImages,
      [index]: true,
    }));
  };

  const handleItemClick = (route) => {
    navigate(route);
  };

  return (
    <div className={styles['feature-row-container']}>
      {features.map((feature, index) => (
        <div
          className={styles['feature-row-item']}
          key={index}
          onClick={() => handleItemClick(feature.route)}
          style={{ cursor: 'pointer' }}
        >
          <div className={styles['feature-row-content']}>
            <div className={styles['feature-row-image-wrapper']}>
              <img
                src={feature.imgSrc}
                alt={feature.title}
                className={`${styles['feature-row-image']} ${loadedImages[index] ? styles['loaded'] : styles['loading']}`}
                loading="lazy"
                onLoad={() => handleImageLoad(index)} // Handle image load
              />
              {!loadedImages[index] && <div className={styles['image-placeholder']}></div>}
            </div>
            <div className={styles['feature-row-text']}>
              <h3 className={styles['feature-row-title']}>{feature.title}</h3>
              <p className={styles['feature-row-description']}>{feature.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatureRow;
