import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Sidebar.module.css';


const Sidebar = ({ collapsed }) => {
  return (
    <div className={`${styles.sidebar} ${collapsed ? styles.collapsed : ''}`} id="sidebar">
      <div className={styles['sidebar-nav']}>
        {/* Link to Camera Browse (/browse) */}
        <Link to="/browse" className={styles['sidebar-menu-item']} id="browse-button">
          <i className="fa-solid fa-briefcase"></i>
          {!collapsed && <span>Browse</span>}
        </Link>

        {/* Link to Radio Browse (/radio) */}
        <Link to="/radio" className={styles['sidebar-menu-item']} id="radio-button">
          <i className="fa-solid fa-radio"></i>
          {!collapsed && <span>Radio</span>}
        </Link>

        {/* Link to Map View (/) */}
        <Link to="/explore" className={styles['sidebar-menu-item']} id="map-button">
          <i className="fa-solid fa-map"></i>
          {!collapsed && <span>Map</span>}
        </Link>
      </div>

      <div className={styles['section-divider']}></div>

      {/* Popular Locations Section */}
      <div className={`${styles['popular-section']} ${collapsed ? styles.collapsed : ''}`}>
        <div className={styles['left-section']}>
          {!collapsed && <h2>Popular Locations</h2>}
        </div>
        <div className={`${styles['collapsed-header']} ${collapsed ? styles.show : ''}`}>
          <i className="fas fa-heart"></i>
        </div>
      </div>

      <div className={styles['sidebar-items']} id="sidebar1">
        {/* Amsterdam */}
        <Link
          to="/browse/Netherlands"
          className={styles['country-item']}
        >
          <img src="https://flagcdn.com/w320/nl.png" alt="Flag" className={styles['flag-icon']} />
          <div className={styles['browsetext-container']}>
            <span className={styles['country-name']}>Netherlands</span>
            <span className={styles['region-name']}>Europe</span>
          </div>
        </Link>

        {/* New York */}
        <Link
          to="/browse/United-States"
          className={styles['country-item']}
        >
          <img src="https://flagcdn.com/w320/us.png" alt="Flag" className={styles['flag-icon']} />
          <div className={styles['browsetext-container']}>
            <span className={styles['country-name']}>United States</span>
            <span className={styles['region-name']}>North America</span>
          </div>
        </Link>

        {/* Paris */}
        <Link
          to="/browse/France"
          className={styles['country-item']}
        >
          <img src="https://flagcdn.com/w320/fr.png" alt="Flag" className={styles['flag-icon']} />
          <div className={styles['browsetext-container']}>
            <span className={styles['country-name']}>France</span>
            <span className={styles['region-name']}>Europe</span>
          </div>
        </Link>

        {/* Tokyo */}
        <Link
          to="/browse/Japan"
          className={styles['country-item']}
        >
          <img src="https://flagcdn.com/w320/jp.png" alt="Flag" className={styles['flag-icon']} />
          <div className={styles['browsetext-container']}>
            <span className={styles['country-name']}>Japan</span>
            <span className={styles['region-name']}>Asia</span>
          </div>
        </Link>

        {/* Venice */}
        <Link
          to="/browse/Italy"
          className={styles['country-item']}
        >
          <img src="https://flagcdn.com/w320/it.png" alt="Flag" className={styles['flag-icon']} />
          <div className={styles['browsetext-container']}>
            <span className={styles['country-name']}>Italy</span>
            <span className={styles['region-name']}>Europe</span>
          </div>
        </Link>
      </div>
    </div>

  );
};

export default Sidebar;
