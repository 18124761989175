import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminContext } from '../../AdminContext';
import styles from './StreamChecker.module.css';
import { Helmet } from 'react-helmet-async';

// Access YouTube API Key from environment variables
const YOUTUBE_API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;

const LIVE_CAMERAS_FILE = 'https://server.earf.tv/live-cameras';
const BROWSE_CAMERAS_FILE = 'https://server.earf.tv/browse-cameras';

const StreamCheckerPage = () => {
  const { isAdminAuthenticated } = useContext(AdminContext);
  const navigate = useNavigate();

  const [liveStreams, setLiveStreams] = useState([]);
  const [browseStreams, setBrowseStreams] = useState([]);
  const [hiddenStreams, setHiddenStreams] = useState([]);
  const [lastFixedStream, setLastFixedStream] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');
  const [selectedStream, setSelectedStream] = useState(null);
  const [searchQuery, setSearchQuery] = useState(''); // New state for search input
  useEffect(() => {
    if (!isAdminAuthenticated) {
      navigate('/admin/login');
    } else {
      const checkStreams = async () => {
        setLoading(true);
        try {
          const fetchStreamsFromFile = async (file) => {
            const response = await fetch(file, {
              headers: {
                'x-api-key': process.env.REACT_APP_EARF_API_KEY,
                'Content-Type': 'application/json',
              },
            });
            const geoJsonData = await response.json();
            return geoJsonData.features;
          };

          const liveCameras = await fetchStreamsFromFile(LIVE_CAMERAS_FILE);
          const browseCameras = await fetchStreamsFromFile(BROWSE_CAMERAS_FILE);

          const processStreamData = async (features) => {
            return await Promise.all(
              features.map(async (feature) => {
                const { videoId, name, tag1, tag2, tag3, wikidata, website } = feature.properties;
                const youtubeApiUrl = `https://www.googleapis.com/youtube/v3/videos?part=snippet,liveStreamingDetails&id=${videoId}&key=${YOUTUBE_API_KEY}`;
                const youtubeResponse = await fetch(youtubeApiUrl);
                const youtubeData = await youtubeResponse.json();

                if (youtubeData.items.length > 0) {
                  const videoItem = youtubeData.items[0];
                  const liveBroadcastContent = videoItem.snippet.liveBroadcastContent;
                  const channelId = videoItem.snippet.channelId;
                  const channelUrl = `https://www.youtube.com/channel/${channelId}/streams`;

                  return {
                    id: videoId,
                    name,
                    videoId,
                    channelUrl,
                    wikidata,
                    website,
                    isLive: liveBroadcastContent === 'live',
                    tags: [tag1, tag2, tag3].filter(Boolean),
                    rawData: feature,
                  };
                }

                return {
                  name,
                  videoId,
                  channelUrl: '',
                  wikidata,
                  website,
                  isLive: false,
                  tags: [tag1, tag2, tag3].filter(Boolean),
                  rawData: feature,
                };
              })
            );
          };

          const updatedLiveStreams = await processStreamData(liveCameras);
          const updatedBrowseStreams = await processStreamData(browseCameras);

          setLiveStreams(updatedLiveStreams);
          setBrowseStreams(updatedBrowseStreams);
        } catch (error) {
          console.error('Error fetching streams:', error);
          setError('Failed to load streams');
        } finally {
          setLoading(false);
        }
      };

      checkStreams();
    }
  }, [isAdminAuthenticated, navigate]);

  const getFilteredStreams = (streams) => {
    let result = streams;
    if (filter === 'online') {
      result = result.filter((stream) => stream.isLive);
    } else if (filter === 'offline') {
      result = result.filter((stream) => !stream.isLive);
    }

    // Apply search filter based on any stream property
    if (searchQuery.trim() !== '') {
      const lowerQuery = searchQuery.toLowerCase();
      result = result.filter((stream) =>
        Object.values(stream).some((value) =>
          typeof value === 'string' && value.toLowerCase().includes(lowerQuery)
        )
      );
    }

    return result;
  };

  const getVisibleStreams = (streams) => {
    return streams.filter((stream) => !hiddenStreams.includes(stream));
  };
  // Function to handle tag click and open in a new tab
  const handleTagClick = (tag) => {
    window.open(`/Browse/${tag}`, '_blank');
  };

  // Handle "Check Stream" click that opens /Browse/<Tag>/video/<videoId> in a new tab
  const handleCheckStreamClick = (tag, videoId) => {
    window.open(`/stream/${videoId}`, '_blank');
  };

  // Handle marking a stream as fixed
  const handleMarkAsFixed = (stream) => {
    setHiddenStreams([...hiddenStreams, stream]);
    setLastFixedStream(stream);
  };

  // Restore the last marked as fixed stream
  const handleRestoreLastFixed = () => {
    setHiddenStreams(hiddenStreams.filter((stream) => stream !== lastFixedStream));
    setLastFixedStream(null);
  };

  // Handle stream item click (open JSON editor)
  const handleStreamClick = (stream) => {
    setSelectedStream(stream);
  };

  const handleSaveClick = async () => {
    try {
      // Determine if the stream is from live streams or browse streams
      const isLiveStream = liveStreams.some((stream) => stream.videoId === selectedStream.videoId);

      // Set the appropriate API URL based on the stream type
      const apiUrl = isLiveStream
        ? `https://server.earf.tv/live-cameras/${selectedStream.videoId}`
        : `https://server.earf.tv/browse-cameras/${selectedStream.videoId}`;

      // Send the PUT request to the correct API endpoint
      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_EARF_API_KEY,
        },
        body: JSON.stringify(selectedStream.rawData),
      });

      if (response.ok) {
        alert('Stream updated successfully');
        setSelectedStream(null); // Close modal on success
      } else {
        alert('Error updating stream');
      }
    } catch (error) {
      console.error('Error saving stream:', error);
      alert('Error saving stream');
    }
  };

  const handleFileDownload = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl, {
        headers: {
          'x-api-key': process.env.REACT_APP_EARF_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch file');
      }

      const blob = await response.blob(); // Get the file content as a Blob

      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link); // Append the link to the document body
      link.click(); // Trigger the download
      document.body.removeChild(link); // Clean up after the download
    } catch (error) {
      console.error('Error downloading the file:', error);
      alert('Error downloading the file.');
    }
  };

  // Handle JSON editor changes
  const handleJsonChange = (e) => {
    const updatedJson = e.target.value;
    try {
      // Parse the updated JSON to ensure it's valid before saving it
      const parsedJson = JSON.parse(updatedJson);
      setSelectedStream({
        ...selectedStream,
        rawData: parsedJson,
      });
    } catch (error) {
      console.error("Invalid JSON format");
    }
  };
  // Close the modal editor
  const closeModal = (e) => {
    if (e.target.classList.contains('modal')) {
      setSelectedStream(null);
    }
  };

  if (loading) {
    return              <div className="full-container">
    <div className="spinner-wrapper">
      <div className="spinner"></div> {/* Spinner Element */}
    </div>
  </div>
  }

  if (error) {
    return <div>{error}</div>;
  }
  // Add this function to handle the delete stream functionality
  const handleDeleteStream = async (stream) => {
    try {
      const isLiveStream = liveStreams.some((s) => s.videoId === stream.videoId);
      const apiUrl = isLiveStream
        ? `https://server.earf.tv/live-cameras/${stream.videoId}`
        : `https://server.earf.tv/browse-cameras/${stream.videoId}`;

      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          'x-api-key': process.env.REACT_APP_EARF_API_KEY,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        alert('Stream deleted successfully');
        // Remove the deleted stream from the state
        if (isLiveStream) {
          setLiveStreams(liveStreams.filter((s) => s.videoId !== stream.videoId));
        } else {
          setBrowseStreams(browseStreams.filter((s) => s.videoId !== stream.videoId));
        }
      } else {
        alert('Error deleting stream');
      }
    } catch (error) {
      console.error('Error deleting stream:', error);
      alert('Error deleting stream');
    }
  };
  // Filtered and visible streams for live and browse
  const filteredLiveStreams = getVisibleStreams(getFilteredStreams(liveStreams));
  const filteredBrowseStreams = getVisibleStreams(getFilteredStreams(browseStreams));

  return (
    <div className={styles['stream-checker-container']}>
      <Helmet prioritizeSeoTags>
        <title>Feature Editor | Earf Admin</title>
        <meta name="description" content="Login to the Earf.TV admin panel to manage streams and settings." />
        <meta property="og:image" content="https://earf.tv/metaimage.png" />
        <meta name="twitter:image" content="https://earf.tv/metaimage.png" />
      </Helmet>

      {/* Header with Back Button */}
      <div className={styles['header-container']}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/christmasearfy.png" alt="Earf.TV Logo" className={styles['logo']} />
          <h1>EARF Feature Editor</h1>
        </div>
  {/* Search Input */}
  <div className={styles['search-container']}>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search streams..."
          className={styles['search-input']}
        />
      </div>
        {/* Back Button */}
        <button className='back-button' onClick={() => navigate(-1)}>
          ← Back
        </button>
      </div>

      {/* Container for restore and download buttons */}
      <div className={styles['buttons-container']}>
        {/* File download buttons (left-aligned) */}
        <div className={styles['download-buttons']}>
          <button onClick={() => handleFileDownload(LIVE_CAMERAS_FILE, 'live-cameras.geojson')} className={styles['download-button']}>
            Download Live Cameras
          </button>
          <button onClick={() => handleFileDownload(BROWSE_CAMERAS_FILE, 'browse-cameras.json')} className={styles['download-button']}>
            Download Browse Cameras
          </button>
        </div>

        {/* Button to restore last fixed stream (center-aligned) */}
        {lastFixedStream && (
          <div className={styles['restore-container']}>
            <button className={styles['restore-button']} onClick={handleRestoreLastFixed}>
              Restore Last Fixed Stream
            </button>
          </div>
        )}
      </div>

      <div className={styles['stream-columns-wrapper']}>
        <div className={styles['stream-header']}>Live Streams</div>
        <div className={styles['stream-header']}>Browse Streams</div>
      </div>
        {/* Dropdown to filter between all, online, or offline streams */}
        <div className={styles['filter-container']}>
          <select
            id="filter-select"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className={styles['filter-select']}
          >
            <option value="all">All Streams</option>
            <option value="online">Online Only</option>
            <option value="offline">Offline Only</option>
          </select>
        </div>
      {/* Scrollable Columns */}
      <div className={styles['stream-columns']}>
        {/* Live Streams Column */}
        <div className={styles['stream-column']}>
          {filteredLiveStreams.length > 0 ? (
            filteredLiveStreams.map((stream, index) => (
              <div
                key={index}
                className={`${styles['stream-item']} ${stream.isLive ? styles['online'] : styles['offline']}`}
                onClick={() => handleStreamClick(stream)}
              >
                <div className={styles['stream-info']}>
                  <h3>{stream.name}</h3>
                  <p>{stream.isLive ? 'Online' : 'Offline'}</p>
                  {stream.wikidata && <p>{stream.wikidata}</p>}
                  {stream.website && (
                    <p>
                      <a href={stream.website} target="_blank" rel="noopener noreferrer">
                        {stream.website}
                      </a>
                    </p>
                  )}
                  {/* Display tags */}
                  {stream.tags.length > 0 && (
                    <div className={styles['stream-tags']}>
                      {stream.tags.map((tag, idx) => (
                        <span
                          key={idx}
                          className={styles['tag']}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTagClick(tag);
                          }}
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}
                  {/* Check Stream button */}
                  <button
                    className={styles['delete-button']}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteStream(stream);
                    }}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                  <button
                    className={styles['check-stream-button']}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCheckStreamClick(stream.tags[0], stream.videoId);
                    }}
                  >
                    Check Stream
                  </button>
                  {/* Mark as fixed button */}
                  <button
                    className={styles['mark-fixed-button']}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMarkAsFixed(stream);
                    }}
                  >
                    Mark as Fixed
                  </button>
                </div>
                <a href={stream.channelUrl} target="_blank" rel="noopener noreferrer" className={`${styles['watch-button']} ${stream.isLive ? styles['online-button'] : styles['offline-button']}`}>
                  {stream.isLive ? 'Watch' : 'Channel'}
                </a>
              </div>
            ))
          ) : (
            <p>No {filter} live streams found.</p>
          )}
        </div>

        {/* Browse Streams Column */}
        <div className={styles['stream-column']}>
          {filteredBrowseStreams.length > 0 ? (
            filteredBrowseStreams.map((stream, index) => (
              <div
                key={index}
                className={`${styles['stream-item']} ${stream.isLive ? styles['online'] : styles['offline']}`}
                onClick={() => handleStreamClick(stream)}
              >
                <div className={styles['stream-info']}>
                  <h3>{stream.name}</h3>
                  <p>{stream.isLive ? 'Online' : 'Offline'}</p>
                  {stream.wikidata && <p>{stream.wikidata}</p>}
                  {stream.website && (
                    <p>
                      <a href={stream.website} target="_blank" rel="noopener noreferrer">
                        {stream.website}
                      </a>
                    </p>
                  )}
                  {/* Display tags */}
                  {stream.tags.length > 0 && (
                    <div className={styles['stream-tags']}>
                      {stream.tags.map((tag, idx) => (
                        <span
                          key={idx}
                          className={styles['tag']}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTagClick(tag);
                          }}
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}
                  {/* Check Stream button */}
                  <button
                    className={styles['delete-button']}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteStream(stream);
                    }}
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                  <button
                    className={styles['check-stream-button']}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCheckStreamClick(stream.tags[0], stream.videoId);
                    }}
                  >
                    Check Stream
                  </button>
                  {/* Mark as fixed button */}
                  <button
                    className={styles['mark-fixed-button']}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMarkAsFixed(stream);
                    }}
                  >
                    Mark as Fixed
                  </button>
                </div>
                <a href={stream.channelUrl} target="_blank" rel="noopener noreferrer" className={`${styles['watch-button']} ${stream.isLive ? styles['online-button'] : styles['offline-button']}`}>
                  {stream.isLive ? 'Watch' : 'Channel'}
                </a>
              </div>
            ))
          ) : (
            <p>No {filter} browse streams found.</p>
          )}
        </div>
      </div>

      {selectedStream && (
        <div className={styles['modal']} onClick={closeModal}>
          <div className={styles['modal-content']}>
            <textarea
              className={styles['json-editor']}
              value={JSON.stringify(selectedStream.rawData, null, 2)}
              onChange={handleJsonChange} // Allow editing
            />
            {/* Save and Close buttons */}
            <div className={styles['modal-buttons']}>
              <button onClick={handleSaveClick} className={styles['save-button']}>Save</button>
              <button onClick={() => setSelectedStream(null)} className={styles['close-button']}>Close</button>
            </div>
          </div>
        </div>
      )}

    </div>

  );
};

export default StreamCheckerPage;
