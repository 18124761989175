import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import styles from './SimilarVideosSidebar.module.css'; // Updated import for CSS module

const SimilarVideosSidebar = ({ wikidata, tags, currentVideoId }) => {
  const [similarVideos, setSimilarVideos] = useState([]);
  const [headerText, setHeaderText] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Debounced resize handler
    const handleResize = () => {
      clearTimeout(window.resizeTimeout);
      window.resizeTimeout = setTimeout(() => {
        setIsMobile(window.innerWidth <= 768);
      }, 150);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(window.resizeTimeout);
    };
  }, []);

  useEffect(() => {
    const fetchSimilarVideos = async () => {
      setIsLoading(true);
      try {
        const liveResponse = await fetch('https://server.earf.tv/live-cameras', {
          headers: {
            'x-api-key': process.env.REACT_APP_EARF_API_KEY,
            'Content-Type': 'application/json',
          },
        });
        const liveData = await liveResponse.json();

        const browseResponse = await fetch('https://server.earf.tv/browse-cameras', {
          headers: {
            'x-api-key': process.env.REACT_APP_EARF_API_KEY,
            'Content-Type': 'application/json',
          },
        });
        const browseData = await browseResponse.json();

        const allVideos = [...(liveData.features || []), ...(browseData.features || [])];

        let relatedVideos = allVideos.filter(
          (video) =>
            video.properties.wikidata === wikidata &&
            video.properties.videoId !== currentVideoId
        );

        if (relatedVideos.length > 0) {
          setHeaderText('Nearby');
        } else {
          relatedVideos = allVideos.filter((video) => {
            const { tag1, tag2, tag3 } = video.properties;
            return (
              (tags.includes(tag1) || tags.includes(tag2) || tags.includes(tag3)) &&
              video.properties.videoId !== currentVideoId
            );
          });
          setHeaderText('Similar');
        }

        setSimilarVideos(relatedVideos);
      } catch (error) {
        console.error('Error fetching similar videos:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (wikidata || tags.length > 0) {
      fetchSimilarVideos();
    }
  }, [wikidata, tags, currentVideoId]);

  const handleVideoClick = (videoId, tag2) => {
    navigate(`/stream/${videoId}`, { state: { fromSidebar: true, tag: tag2 } });
  };

  const displayedVideos = isMobile ? similarVideos.slice(0, 2) : similarVideos;

  return (
    <div className={`${styles['similar-videos-sidebar']} ${isLoading ? '' : styles['fade-in']}`}>
      <h3>
        {headerText} <span className={styles['highlight-blue']}>Locations</span>
      </h3>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles['sidebar-scrollable-content']}>
          {displayedVideos.length > 0 ? (
            displayedVideos.map((video) => (
              <div
                className={styles['similar-video-item']}
                onClick={() => handleVideoClick(video.properties.videoId, video.properties.tag2)}
                key={video.properties.videoId}
              >
                <img
                  src={`https://img.youtube.com/vi/${video.properties.videoId}/mqdefault.jpg`}
                  alt={video.properties.name}
                  className={styles['similar-video-thumbnail']}
                />
                <div className={styles['similar-video-content']}>
                  <p>{video.properties.name}</p>
                  <p className={styles['wikidata']}>
                    {video.properties.wikidata}
                  </p>
                  <div className={styles['sidebar-tags']}>
                    {[video.properties.tag1, video.properties.tag2, video.properties.tag3]
                      .filter(Boolean)
                      .map((tag, index) => (
                        <span key={index} className={styles['sidebar-tag']}>
                          {tag}
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No related videos found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default SimilarVideosSidebar;
